import { showToast, hideLoading } from './VantUtils';
import { OPEN_LOGIN, SIGN_OUT } from '@/utils/Agreement';

/**
 * 处理接口异常
 * @param {*} that
 * @param {*} error
 */
export function handlerEror(error) {
  if (error == undefined) return;

  if (error.code == 0 || error.code == undefined) return;

  //未登录情况调用登录(首页贷款大全不弹登录)
  let selectTab = sessionStorage.getItem('selectTab');
  if (selectTab != 'home' || selectTab != 'dkdq') {
    if (error.code == -8 || error.code == -1) {
      window.location.href = SIGN_OUT;
      localStorage.removeItem('token');
      hideLoading();
      setTimeout(() => {
        window.location.href = OPEN_LOGIN;
      }, 200);
      return;
    }
  }

  if (error.desc != undefined && error.desc != '') {
    showToast(error.desc);
    return;
  }

  if (error.message != undefined && error.message != '') {
    showToast(error.message);
    return;
  }
}

/**
 * 判断设备系统
 */
export function isAndroid() {
  let u = navigator.userAgent;
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    return true;
  }
  return false;
}

/**
 * 判断版本号导航显示隐藏
 */
export function isNavBarShow() {
  let aliasCode = localStorage.getItem('aliasCode');
  let system = aliasCode ? aliasCode : localStorage.getItem('system');
  let appVersion = localStorage.getItem('appVersion');
  if (isAndroid()) {
    if (system == 'zlqb' || (system == 'qyd' && appVersion >= '2.2.6') || (system == 'hxj' && appVersion >= '1.1.2')) {
      console.log('不需要高度');
      return false;
    }
    console.log('需要高度');
    return true;
  }
  console.log('需要高度');
  return true;
}

/**
 * 空值判断
 * @param {传入判断空值} param
 */
export function checkVar(param) {
  if (param == '' || param == undefined || param == null || param == 'null' || param == 'undefined') {
    return true;
  }
  return false;
}

/**
 * 登录状态判断
 */
export function checkLoginStatus() {
  let token = localStorage.getItem('token');
  if (token != '' && token != undefined) {
    return true;
  }
  return false;
}
/**
 * 跳转链接参数拼接
 */
export function urlSpliceParameter(url) {
  let timestamp = new Date().getTime();
  let parameter = '';
  //判断url是否含有？
  if (url.indexOf('?') != -1) {
    parameter = '&timestamp=' + timestamp;
  } else {
    parameter = '?timestamp=' + timestamp;
  }

  if (url.indexOf('zlqbType') == -1) parameter = parameter + '&zlqbType=app';

  if (url.indexOf('zlqbSessionId') == -1) {
    let token = localStorage.getItem('token');
    if (token != '' && token != undefined) {
      parameter = parameter + '&zlqbSessionId=' + token;
    }
  }

  if (url.indexOf('system') == -1) {
    let system = localStorage.getItem('system');
    if (system != '' && system != undefined) {
      parameter = parameter + '&system=' + system;
    }
  }

  if (url.indexOf('platform') == -1) {
    let platform = localStorage.getItem('platform');
    if (platform != '' && platform != undefined) parameter = parameter + '&platform=' + platform;
  }

  return url + parameter;
}

export function saveCache(query) {
  if (query != undefined && query.zlqbSessionId != undefined) {
    /**
     * 处理重复sessionId的问题
     */
    let sessionId = query.zlqbSessionId;
    if (sessionId != undefined && sessionId != 'undefined') {
      if (Array.isArray(sessionId)) {
        localStorage.setItem('token', sessionId[0]);
      } else {
        localStorage.setItem('token', sessionId);
      }
    }

    /**
     * 处理重复system的问题
     */
    let system = query.system;
    if (system != undefined && system != 'undefined') {
      if (Array.isArray(system)) {
        localStorage.setItem('system', system[0]);
      } else {
        localStorage.setItem('system', system);
      }
    }
    /**
     * 处理appType
     */
    let appType = query.appType;
    if (appType != undefined && appType != 'undefined') {
      if (Array.isArray(appType)) {
        localStorage.setItem('appType', appType[0]);
      } else {
        localStorage.setItem('appType', appType);
      }
    }
    /**
     * 处理重复version的问题
     */
    let version = query.version;
    if (version != undefined && version != 'undefined') {
      if (Array.isArray(version)) {
        localStorage.setItem('appVersion', version[0]);
      } else {
        localStorage.setItem('appVersion', version);
      }
    }

    //处理aliasCode
    let aliasCode = query.aliasCode;
    if (aliasCode != undefined && aliasCode != 'undefined') {
      if (Array.isArray(aliasCode)) {
        localStorage.setItem('aliasCode', aliasCode[0]);
      } else {
        localStorage.setItem('aliasCode', aliasCode);
      }
    }

    let channel = query.channel;
    if (channel != undefined && channel != 'undefined') {
      if (Array.isArray(channel)) {
        localStorage.setItem('channel', channel[0]);
      } else {
        localStorage.setItem('channel', channel);
      }
    }

    /**
     * 安卓审核开关
     */
    let switchStatus = query.switchStatus;
    if (switchStatus != undefined && switchStatus != 'undefined') {
      if (Array.isArray(switchStatus)) {
        localStorage.setItem('switchStatus', switchStatus[0]);
      } else {
        localStorage.setItem('switchStatus', switchStatus);
      }
    }

    if (isAndroid()) {
      localStorage.setItem('platform', 'android');
      localStorage.setItem('clientType', 1);
    } else {
      localStorage.setItem('platform', 'IOS');
      localStorage.setItem('clientType', 2);
    }
  }
}

export function getUrlParams() {
  const url = window.location.href;
  const index = url.indexOf('?');
  return url.slice(index);
}

/**
 * 下拉加载
 * dom：文档内容容器
 * fn：滚动条滚到指定位置触发的方法
 */
export function onScroll(dome, fn) {
  window.onscroll = () => {
    let scrollTop = 0;
    if (document.body.scrollTop) {
      scrollTop = document.body.scrollTop;
    } else if (document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    }
    let bottomOfWindow = document.querySelector(dome).scrollHeight - document.documentElement.clientHeight - scrollTop;
    if (bottomOfWindow <= 1) {
      fn.call();
    }
  };
}

/**
 * 信息脱敏(from 紫阳大佬)
 */
export function dataMasking(data, type) {
  let dataLength = data.length;
  let msak = '';
  let maskTest = '';
  if (type == 'idcard') {
    let beforeId = data.substring(0, 3);
    let numText = data.substring(dataLength - 4, dataLength);
    maskTest = data.substring(3, dataLength - 4);
    for (let i = 0; i < maskTest.length; i++) {
      msak = msak + '*';
    }
    return beforeId + msak + numText;
  }
  if (type == 'phone') {
    let beforeNum = data.substring(0, 3);
    let afterNum = data.substring(7, 11);
    maskTest = data.substring(3, 7);
    for (let i = 0; i < maskTest.length; i++) {
      msak = msak + '*';
    }
    return beforeNum + msak + afterNum;
  }
  if (type == 'checkHomeNum') {
    //首页的加密规则
    let numText = data.substring(dataLength - 4, dataLength);
    maskTest = data.substring(0, dataLength - 4);
    for (let i = 0; i < maskTest.length; i++) {
      msak = msak + '*';
    }
    return msak + numText;
  }
  if (type == 'checkHomeName') {
    //首页的加密规则
    let nameText = data.substring(dataLength - 1, dataLength);
    maskTest = data.substring(0, dataLength - 1);
    for (let i = 0; i < maskTest.length; i++) {
      msak = msak + '*';
    }
    return msak + nameText;
  }
  let nameText = data.substring(0, 1);
  maskTest = data.substring(1, dataLength);
  for (let i = 0; i < maskTest.length; i++) {
    msak = msak + '*';
  }
  return nameText + msak;
}

/**
 * 计算年龄(from 紫阳大佬)
 */
export function getAge(idcard) {
  let nowTime = new Date().getFullYear();
  let userAge = idcard.toString().slice(6, 10);
  return nowTime - userAge - 1; //周岁
}

// 电话号码正则校验
export function checkPhone(phone) {
  // const phonePattern = /(^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$)|(^(([0\+]\d{2,3})?(0\d{2,3}))(\d{7,8})((\d{3,}))?$)|(^0{0,1}1[3|4|5|6|7|8|9][0-9]{9}$)/;
  if (phone.length <= 0) {
    return false;
  }
  return true;
}

/***
 * 检测已实名信息是否重新输入
 */
export function testMaskingNewValue(val) {
  let testText = new RegExp('[*]');
  if (!testText.test(val)) {
    return true;
  }
  return false;
}

/*
 * 拨打电话
 * @param {*} phoneNum
 */
export function callPhone(phoneNum) {
  window.location.href = 'tel://' + phoneNum;
}

/**
 * 函数节流
 * @param fn 传入的函数
 * @param wait 需要等待的时间
 */
export function throttle(that, fn, wait) {
  let timer = null;
  return function() {
    let args = arguments;
    if (!timer) {
      timer = setTimeout(function() {
        fn.apply(that, args);
        timer = null;
      }, wait);
    }
  };
}

// 滚动条问题
export function backTop() {
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0;
  }
}

// 智能匹配数字滚动
export function numShow(w, h, dom) {
  let count = 10;
  let meteors = [];
  let mouseX = 0;
  let mouseY = 0;
  let width = w;
  let height = h;
  let canvas = document.getElementById(dom);
  let ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;

  init();
  setInterval(run, 40);

  function randH(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  function init() {
    mouseX = width / 2; //中心点
    mouseY = height / 2; //中心点
    for (let i = 0; i < count; ++i) {
      meteors[i] = new Meteor(ctx, width, height);
    }
  }
  function run() {
    ctx.clearRect(0, 0, width, height);
    for (let i = 0; i < count; ++i) {
      meteors[i].num = Math.floor(Math.random() * 201 + 1);
      if (meteors[i].font == 3.2) {
        meteors[i].font = 3.2;
      } else {
        meteors[i].font -= 0.1;
      }
      meteors[i].opacity -= 0.02;
      meteors[i].draw(mouseX, mouseY);
    }
  }
}
/**
 * 数字回到原点动画
 */
export class Meteor {
  constructor(ctx, width, height) {
    this.ctx = ctx;
    this.width = width;
    this.height = height;
    this.tx = 0;
    this.ty = 0;
    this.font = 5.33;
    this.opacity = 1;
    this.maxLen = 8;
    this.len = 0;
    this.center = 5;
    this.randomXY();
  }
  randomXY() {
    this.tx = Math.random() * this.width;
    this.ty = Math.random() * this.height;
    this.num = Math.floor(Math.random() * 201 + 1);
    this.font = 5.33;
    this.opacity = 1;
  }
  draw(mx, my) {
    let c = Math.sqrt(Math.pow(this.tx - mx, 2) + Math.pow(this.ty - my, 2));
    if (c <= 10 || Math.random() > 0.99) {
      this.randomXY();
    } else {
      this.tx -= ((this.tx - mx) * this.len) / c;
      this.ty -= ((this.ty - my) * this.len) / c;
    }
    if (this.len < this.maxLen) {
      this.len += 2;
    }
    this.ctx.fillStyle = `rgba(255,255,255,${this.opacity})`;
    this.ctx.beginPath();
    this.ctx.font = this.font + 'vw 微软雅黑';
    this.ctx.fillText(this.num, this.tx, this.ty);
  }
}

/**
 *
 * @param {认证类型} type
 * @param {认证状态} attestation
 * @param {当前页面的this对象} that
 * @param {跳资质认证页面需要的参数} params
 *
 */
export function toCertification(type, attestation, that, params) {
  if (attestation == '1') {
    showToast('您已完成该认证项！');
    return;
  }
  let query = that.$route.query;
  let parameter = window.location.href.toString().split('?')[1];

  const appType = localStorage.getItem('appType');
  switch (type) {
    case 1:
      //身份认证
      if (appType != undefined) {
        that.$router.push({
          path: '/verify/faceOcr'
        });
        break;
      }
      window.location.href = TO_CHECK_STATUS + '?id=0';
      break;
    case 2:
      //联系人认证
      if (appType != undefined) {
        // that.$router.push({
        //     path: '/verify/contact',
        // })
        window.location.href = `${OPEN_NEW_WEBVIEW}?url=${process.env.HOST_URL}verify/contact`;
        break;
      }
      window.location.href = TO_CHECK_CONTACT + '?id=0';
      break;
    case 4:
      //银行卡认证
      window.location.href = `${OPEN_NEW_WEBVIEW}?url=${process.env.HOST_URL}verify/fourBankCard`;
      break;
    case 6:
      //资质认证
      let apiLoanIds = params.apiLoanIds ? '&apiLoanIds=' + params.apiLoanIds : '';
      if (appType != undefined) {
        window.location.href =
          OPEN_NEW_WEBVIEW +
          '?url=' +
          process.env.HOST_URL +
          'verify/qualify?pageName=hxj&checkSuccessType=' +
          params.checkSuccessType +
          apiLoanIds +
          '&' +
          parameter;
        break;
      }
      let newQuery = JSON.parse(JSON.stringify(query));
      newQuery.checkSuccessType = params.checkSuccessType;
      newQuery.pageName = 'hxj';
      if (params.apiLoanIds) {
        newQuery.apiLoanIds = params.apiLoanIds;
      }
      that.$router.push({
        path: '/verify/qualify',
        query: newQuery
      });
      break;
    case 10:
      //实名认证
      if (appType != undefined) {
        window.location.href = `${OPEN_NEW_WEBVIEW}?url=${process.env.HOST_URL}verify/signRealName?${parameter}`;
        break;
      }
      that.$router.push({
        path: '/check/signRealName',
        query: query
      });
      break;
    default:
      break;
  }
}

/**
 * 是否审核包
 * true 审核版本 隐藏相关产品，会员相关，ui;
 */
export function checkSpecial() {
  const switchStatus = localStorage.getItem('switchStatus');
  if (switchStatus == undefined || switchStatus != 1) return false;
  return true;
}

// // 获取URL里面的参数
export function getQueryVariable(name, url) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = url.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

/**
 * 格式化银行卡号、手机号
 * 区分首3位后4位空格格式（188 8888 8888）、
 * 4位一空格格式（6228 1832 3233 ****）
 * @param {*} originalValue 格式化前数据
 */
export function inputFormatting(originalValue) {
  let value = originalValue.toString();
  //先去除所有空格再格式化
  value = value.replace(/(\s*)/g, '');
  //处理分隔显示
  if (value.length <= 11) {
    //非1开头
    value = value.replace(/(^\d{3}|\d{4}\B)/g, '$1 ');
  } else {
    value = value.replace(/(^\d{4}|\d{4}\B)/g, '$1 ');
  }
  //去除末尾空格
  return value.replace(/(\s*$)/g, '');
}

export function saveUserToken(that, token) {
  localStorage.setItem('token', token);
  that.$store.state.main.isLoadDialogOrFloat = false;
}
/**
 * 根据版本号区别安卓展示
 * （助力省钱or助力钱包）
 */
export function distinguishZlqbVersion() {
  let appVersion = localStorage.getItem('appVersion');
  if (appVersion != undefined && (appVersion == '2.9.3' || appVersion == '2.9.4')) return true;
  return false;
}
