import { IMG_GROUP, INIT_IMGURL } from './common';

export default {
  namespaced: true,
  state: {
    imgUrlList: [
      {
        reallyUrl: 'https://img01.yzcdn.cn/vant/leaf.jpg'
      },
      {
        reallyUrl: 'https://img01.yzcdn.cn/vant/tree.jpg'
      },
      {
        reallyUrl: 'https://img01.yzcdn.cn/vant/sand.jpg'
      }
    ] //图片存放地址
  },
  mutations: {
    [IMG_GROUP](state, data) {
      state.imgUrlList.push(data);
    },
    [INIT_IMGURL](state) {
      state.imgUrlList = [];
    }
  },
  actions: {}
};
