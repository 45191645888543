/**
 * 适用APP跳转协议
 */

let HTTP = window.location.origin;
/**
 * SIGN_OUT 退出登录
 * OPEN_LOGIN  调用登录
 * BACK_TO_MAIN  回到首页
 * TO_MAIN  跳转首页
 */
export const SIGN_OUT = 'zlqb://appLogout',
  OPEN_LOGIN = 'zlqb://jumpToLogin',
  BACK_TO_MAIN = 'zlqb://backToMain',
  TO_MAIN = 'zlqb://toMain';
/**
 * 页面跳转类协议
 * CLOSE_WEBVIEW  关闭APPweb视窗
 * OPEN_NEW_WEBVIEW  打开新web视窗
 * BACK_PAGE  物理键返回关闭页面
 * OPEN_BACK_FORWARD  iOS APP打开侧滑
 * CLOSE_BACK_FORWARD  iOS APP关闭侧滑
 */
export const CLOSE_WEBVIEW = 'zlqb://closeWebView',
  OPEN_NEW_WEBVIEW = 'zlqb://openNewWebView',
  OPEN_NEW_WEBVIEW_IP = `zlqb://openNewWebView?url=${HTTP}`,
  OPEN_OLD_WEBVIEW = 'zlqb://openOldWebView',
  BACK_PAGE = 'zlqb://backToFinish',
  BAR_HIDDEN = 'zlqb://navigationBarHidden',
  OPEN_BACK_FORWARD = 'zlqb://openBackForward',
  CLOSE_BACK_FORWARD = 'zlqb://forbidBackForward',
  CLEAR_CACHE = 'zlqb://clearCache',
  CLEAR_APP_UPDATE = 'zlqb://checkAppUpdates';

/**
 * 产品申请类跳转协议
 * TO_PRODUCT_DETAIL  跳转产品详情
 * TO_MAIN_LOAN  跳转贷款大全
 * REFUSE_TO_PAY  申请拒就赔产品
 * CREDITCARD_LOAN  安卓APP信用卡中心跳转贷款大全信用卡贷款分类
 * API_ONECLICK_APPLY_SUCCESS  一键申请成功跳转查看订单
 * SET_PRODUCT_ID   跳转认证等协议提前设置id 后带参数: ?id=productId
 */
export const TO_PRODUCT_DETAIL = 'zlqb://productDetail',
  TO_MAIN_LOAN = 'zlqb://mainLoan',
  REFUSE_TO_PAY = 'zlqb://refuseToPay',
  CREDITCARD_LOAN = 'zlqb://queryMarketFilter',
  API_ONECLICK_APPLY_SUCCESS = 'zlqb://apiApplySuccess',
  SET_PRODUCT_ID = 'zlqb://setProductId';

/**
 * 检测类跳转协议
 * QUERY_BLACKLIST  黑名单检测
 * QUERY_CONTACT    联系人检测
 * QUERY_OPERATOR   运营商检测
 */
export const QUERY_BLACKLIST = 'zlqb://queryBlackList',
  QUERY_CONTACT = 'zlqb://queryContact',
  QUERY_OPERATOR = 'zlqb://queryOperator';

/**
 * 认证类跳转协议
 * TO_CHECK_STATUS  活体认证
 * TO_CHECK_CONTACT  联系人认证
 * TO_CHECK_BANK  银行卡认证
 * TO_CHECK_OPERATOR  运营商认证
 * TO_FRONT_REALNAME  跳转前置实名页
 * TO_SELF_CHECK_BANK  自己公司使用绑卡认证
 * TO_CHECK_PERSONAL   资质认证
 */
export const TO_CHECK_STATUS = 'zlqb://toCheckStatus',
  TO_CHECK_CONTACT = 'zlqb://toCheckContact',
  TO_CHECK_BANK = 'zlqb://toCheckBank',
  TO_CHECK_OPERATOR = 'zlqb://toCheckOperator',
  TO_FRONT_REALNAME = 'zlqb://FrontRealName',
  TO_SELF_CHECK_BANK = 'zlqb://toCheckLLBank',
  TO_CHECK_PERSONAL = 'zlqb://toCheckPersonal';

/**
 * 分享类跳转协议
 * WX_SHARE  分享小弹窗
 * OPEN_WECHAT  打开微信app
 */
export const WX_SHARE = 'zlqb://wxShare',
  OPEN_WECHAT = 'zlqb://openWeChat';

/**
 * 开通类跳转协议
 * TO_OPEN_VIP   开通会员
 */
export const TO_OPEN_VIP = 'zlqb://toOpenVip',
  PAY_SUCCESS_CALLBACK = 'zlqb://paySuccessCallback';

/**
 * IOS APP专用类跳转协议
 * REFRESH_USERINFO   刷新APP用户信息
 * CREATE_RIGHT_ITEM      IOS导航条右侧按钮信息及跳转url传递
 */
export const REFRESH_USERINFO = 'zlqb://getUserInfo',
  CREATE_RIGHT_ITEM = 'zlqb://createRightItem';

/**
 * 安卓在线客服跳转协议
 */
export const ONLINE_CONSUMER_SERVICE = 'zlqb://openSobotService';

/**
 * 刷新订单列表，并关闭容器
 */

export const API_REFRESH_PAY = 'zlqb://apiRefreshRepayPage';

/**
 * 设置状态栏颜色
 */
export const SET_STATUS_COLOR = 'zlqb://setStatusColor';

/**
 * 请求获取定位权限
 */
export const GET_LOCATION_STATUS = 'zlqb://getLocationStatus';
/**
 * 请求上报设备信息
 */
export const REPORT_DEVICE_INFO = 'zlqb://reportDeviceInfo';
/**
 * 请求打开设置
 */
export const TO_APP_SETTING = 'zlqb://toAppSetting';
/**
 * 注册观察者(ios)
 */
export const APPLICATION_ENTER = 'zlqb://applicationEnterForeground';

/**
 * 请求联系人权限
 */
export const GET_CONTACTS_STATUS = 'zlqb://getContactsStatus';
/**
 * 上报联系人信息
 */
export const REPORT_CONTACTS = 'zlqb://reportContacts';
/**
 * 选择联系人
 */
export const SELECT_CONTACT_USER = 'zlqb://selectContactUser';

/**
 * face++ 正反照片
 */
export const FACE_ID_CARD = 'zlqb://MGFaceIDIdCard?type=';

/**
 * face++ 活体
 */
export const FACE_LIVE = 'zlqb://MGFaceIDLiveDetect?token=';

/**
 * face++ 授权
 */
export const FACE_AUTH = 'zlqb://MGlicenseForNetwork';

/**
 * 安卓好享借流程立即拿钱跳转
 */
export const SET_DOWNLOAD_TYPE = 'zlqb://setDownloadType';

/**
 * 打开录屏功能
 */
export const OPEN_RECORD_SCREEN = 'zlqb://openRecordScreen';

/**
 * 关闭录屏功能
 */
export const CLOSE_RECORD_SCREEN = 'zlqb://closeRecordScreen';

/**
 * 实名相关
 * checkRealAuth 检查是否实名
 * goToRealAuth 拉起实名
 * getRealAuth(status)获取实名状态的方法
 */
export const CHECK_REAL_AUTH = 'zlqb://checkRealAuth';
export const GO_REAL_AUTH = 'zlqb://goToRealAuth';
