import { Toast, Dialog } from 'vant';

export function showToast(text) {
  Toast({
    message: text
  });
}

export function showLoading(text) {
  if (text == undefined) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    });
    return;
  }
  Toast.loading({
    message: text,
    forbidClick: true
  });
}
export function hideLoading() {
  Toast.clear();
}

export function showPrompt(text) {
  Dialog.alert({
    message: text,
    theme: 'round-button'
  });
}

export function showCallBackPrompt(text, callback) {
  return Dialog.alert({
    message: text,
    theme: 'round-button'
  }).then(() => {
    callback();
  });
}
