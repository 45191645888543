import Vue from 'vue';
import VueRouter from 'vue-router';

import accounts from '@/views/accounts/index';
import gxys from '../views/guide';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  ...accounts,
  ...gxys
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
