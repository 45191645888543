export default [
  {
    path: '/accounts/communityRecord',
    name: 'CommunityRecord',
    component: () => import('./index.vue'),
    meta: {
      title: '发帖记录',
      keepAlive: true
    }
  }
];
