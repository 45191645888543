import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vConsole from 'vconsole';
import Axios from './utils/Http';

Vue.config.productionTip = false;
Vue.prototype.Axios = Axios;

if (process.env.VUE_APP_ENV != 'production') {
  //调试插件
  const vc = new vConsole();
  Vue.use(vc);
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.afterEach((route) => {
  if (route.meta.title) {
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '../src/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout((_) => {
        document.body.removeChild(hackIframe);
      }, 300);
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
