export default [
  {
    path: '/gxys',
    name: 'gxys',
    component: () => import('./gxys'),
    meta: {
      title: '引导页',
      keepAlive: false
    }
  }
];
