<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { CLOSE_BACK_FORWARD } from './utils/Agreement';
import { isAndroid, saveCache } from './utils/Tools';
export default {
  data() {
    return {
      flag: true,
      transitionName: 'slide-left'
    };
  },

  beforeUpdate() {
    if (!this.flag) {
      return;
    }
    this.flag = false;

    let query = this.$route.query;
    // //审核用处理
    // if (this.$route.path == '/audit') localStorage.setItem('auditPage', '1');
    // //iOS全局关闭页面侧滑,审核页不加载取消侧滑
    // if (!isAndroid() && localStorage.getItem('auditPage') != '1') window.location.href = CLOSE_BACK_FORWARD;

    //计算获取状态栏导航高度
    if (query.statusBarHeight != undefined) {
      localStorage.setItem('statusBarHeight', query.statusBarHeight);
    }
    /**
     * 保存token
     */
    saveCache(query);
  }
};
</script>

<style lang="less">
@import './assets/styles/reset.less';
</style>
