export default [
  {
    path: '/accounts/feedback',
    name: 'Feedback',
    component: () => import('./index.vue'),
    meta: {
      title: '举报'
    }
  }
];
