export default [
  {
    path: '/accounts/chart',
    component: () => import('./index.vue'),
    meta: {
      title: '',
      keepAlive: true
    }
  },
  {
    path: '/jt/chart',
    component: () => import('./JtChart.vue'),
    meta: {
      title: '图表',
      keepAlive: false
    }
  }
];
