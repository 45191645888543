import axios from 'axios';
import qs from 'qs';

// import { isAndroid } from '@/utils/Tools';

/**
 * 设置Base路径
 */
axios.defaults.baseURL = process.env.VUE_APP_BASEURL_API;

/**
 * 拦截response
 */
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//请求超时设置
// axios.defaults.timeout = 15000;

/**
 ** FormData date in for
 ** start
 **/
function encodeMap(map) {
  let uri = [];
  Object.keys(map).forEach((key) => {
    let str = key + '=' + map[key];
    uri.push(str);
  });
  return uri.join('&');
}
function makeFormData(obj, form_data) {
  var data = [];
  if (obj instanceof File) {
    data.push({ key: '', value: obj });
  } else if (obj instanceof Array) {
    for (var j = 0, len = obj.length; j < len; j++) {
      // @ts-ignore
      var arr = makeFormData(obj[j]);
      for (var k = 0, l = arr.length; k < l; k++) {
        var key = !!form_data ? j + arr[k].key : '[' + j + ']' + arr[k].key;
        data.push({ key: key, value: arr[k].value });
      }
    }
  } else if (typeof obj == 'object') {
    // @ts-ignore
    for (var j in obj) {
      // @ts-ignore
      var arr = makeFormData(obj[j]);
      for (var k = 0, l = arr.length; k < l; k++) {
        var key = !!form_data ? j + arr[k].key : '[' + j + ']' + arr[k].key;
        data.push({ key: key, value: arr[k].value });
      }
    }
  } else {
    data.push({ key: '', value: obj });
  }
  if (!!form_data) {
    // 封装
    for (var i = 0, len = data.length; i < len; i++) {
      form_data.append(data[i].key, data[i].value);
    }
  } else {
    return data;
  }
}

/**
 ** FormData date in for
 ** end
 **/
function request(url, method, params, type) {
  params.timestamp = new Date().getTime();

  params.sessionId = '';
  let sessionId = localStorage.getItem('token');
  if (sessionId != undefined) params.sessionId = sessionId;

  params.system = process.env.SYSTEM;
  let system = localStorage.getItem('system');
  if (system != undefined) params.system = system;

  params.platform = 'android';
  // if (!isAndroid()) params.platform = 'IOS';

  params.clientType = 0;
  const clientType = localStorage.getItem('clientType');
  if (clientType != undefined) {
    params.clientType = clientType;
  }

  //app版本号
  let appVersion = localStorage.getItem('appVersion');
  if (appVersion != undefined) params.appVersion = appVersion;
  //app种类
  let aliasCode = localStorage.getItem('aliasCode');
  if (aliasCode != undefined) params.aliasCode = aliasCode;

  if (!type) {
    return new Promise((resolve, reject) => {
      axios({
        method,
        url,
        params: method == 'GET' ? params : null,
        data: method == 'POST' ? qs.stringify(params) : null
      })
        .then((res) => {
          if (res.code != 0) {
            reject(res);
            return;
          }
          resolve(res.data);
        })
        .catch((error) => {
          const result = { code: -100, desc: '出了点问题，请稍后再试！' };
          reject(result);
        });
    });
  }
}
/** type
 * 1.josn 为空不传就是
 * 2.formData  submit
 * 3.其它  待扩展
 **/
function get(url, params, type) {
  return request(url, 'GET', params, type);
}

function post(url, params, type) {
  return request(url, 'POST', params, type);
}

export default {
  get,
  post
};
